import React from 'react'
import { Link } from 'gatsby'
import { PageProps } from 'gatsby'
import Layout from '@/shared/globalStyle'
import SectionStyle from '@/components/atoms/util/SectionStyle'
import tw from 'twin.macro'

const NotFound: React.FC<PageProps> = () => (
  <Layout>
    <section css={SectionStyle(tw`pt-24 lg:pb-14 pb-8`)}>
      <p tw="text-center">お探しのページはございません。</p>
      <Link to="/" tw="block text-center underline lg:mt-8 mt-4">
        トップに戻る
      </Link>
    </section>
  </Layout>
)

export default NotFound
